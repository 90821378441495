<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane
        v-for="item in tabList"
        :label="item.label"
        :name="item.name"
        :key="item.label"
      ></el-tab-pane>
    </el-tabs>
    <el-row class="contentWrapper">
      <el-col :xs="24" :sm="14" class="leftCard">
        <AppDrag
          v-for="item in tabList"
          :key="item.name"
          v-show="activeName == item.name"
          :sourceType="item.name"
          :selectList="selectList"
          :styleCode="styleCode"
          :moduleCode="moduleCode"
          :isShowDesttop="true"
          :tabulatioName="tabulatioName"
          :blockId="blockId"
        ></AppDrag>
      </el-col>
      <el-col :xs="24" :sm="10" class="rightCard">
        <div>提交信息：</div>
        <div class="summitContent borderA">
          <AppReceiverFixCount
            v-if="styleCode == 201"
            :selectList="selectList"
            :styleCode="styleCode"
            class="selectList"
          ></AppReceiverFixCount>
          <AppReceiver202
            v-else-if="styleCode == 202"
            v-model="selectList"
            :styleCode="styleCode"
            class="selectList"
          >
          </AppReceiver202>
          <AppReceiver
            v-else
            :styleCode="styleCode"
            v-model="selectList"
            class="selectList"
          ></AppReceiver>
        </div>
        <div class="optionArea">
          <el-button type="primary" @click="handleSubmit">保存</el-button>
          <el-button type="danger" @click="handleClear">清空</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import AppDrag from "@/components/deploy2/AppDrag";
import AppReceiver from "@/components/deploy2/AppReceiver";
import AppReceiverFixCount from "@/components/deploy2/AppReceiverFixCount";
import AppReceiver202 from "@/components/deploy2/AppReceiver202";

import { getPageConfigDetail, savePageConfig } from "@/api/ozBlockConf";
import { sourceTypeList, sourceTypeList1 } from "@/common/constant/constant";

export default {
  name: "keywordsAd",
  components: { AppDrag, AppReceiver, AppReceiverFixCount, AppReceiver202 },
  props: [
    "blockConfId",
    "blockId",
    "styleCode",
    "deployNum",
    "moduleCode",
    "tabulatioName",
  ],
  data() {
    return {
      activeName: "app",
      selectList: [],
      tabList: sourceTypeList,
      tabList1: sourceTypeList1,
    };
  },
  created() {
    this.getKeywordAdDetail(this.blockConfId);
  },
  methods: {
    handleSubmit() {
      this.keywordAdAdd();
    },
    handleClear() {
      if (this.styleCode == 202 || this.styleCode == 201) {
        const num = this.styleCode == 201 ? 3 : 2;
        let arr = [];
        for (let i = 0; i < num; i++) {
          arr.push({
            imgUrl: "",
            webUrl: "",
            radio: 1,
            isChecked: false,
            dragList: [],
          });
        }
        this.selectList = arr;
      } else {
        this.selectList = [];
      }
    },
    async getKeywordAdDetail(id) {
      let res = await getPageConfigDetail({
        blockConfId: id,
        moduleType: 2,
      });
      const list = res.data || [];

      this.selectList = list.map((item) => {
        item.apkName = item.apkName || item.app.apkName;
        const type = item.resType != 1 && item.resType != 2 ? 3 : item.resType;
        item.sourceType = this.tabList1.find((tl) => tl.id == type)?.name;
        item.dragList = [];
        if (
          this.styleCode == 201 ||
          (this.styleCode == 202 && item.app && item.app.apkId)
        ) {
          item.dragList = [
            {
              apkId: item.apkId,
              apkName: item.apkName,
              packageName: item.packageName,
              iconUrl: item.iconUrl,
              webUrl: item.webUrl,
              isChecked: item.isChecked == 1 ? true : false,
            },
          ];
        }
        item.radio = item.webUrl ? 2 : 1;
        item.isChecked = item.isChecked == 1 ? true : false;
        return item;
      });
      let selectListNum = this.selectList.length;
      let deployNum;
      if (this.styleCode == 202 && selectListNum == 0) {
        for (let i = 0; i < 2; i++) {
          this.selectList.push({
            imgUrl: "",
            webUrl: "",
            radio: 1,
            isChecked: false,
            dragList: [],
          });
        }
      } else if (this.styleCode == 201 && selectListNum <= 0) {
        // deployNum =3; //默认三条
        deployNum = this.deployNum; //获取设置的条数
        for (let i = 0; i < deployNum; i++) {
          this.selectList.push({
            imgUrl: "",
            webUrl: "",
            radio: 1,
            isChecked: false,
            dragList: [],
          });
        }
      } else if (this.styleCode == 201 && selectListNum < this.deployNum) {
        deployNum = this.deployNum - selectListNum;
        for (let j = 0; j < deployNum; j++) {
          this.selectList.push({
            imgUrl: "",
            webUrl: "",
            radio: 1,
            isChecked: false,
            dragList: [],
          });
        }
      } else if (this.styleCode == 201 && selectListNum > this.deployNum) {
        this.selectList = this.selectList.slice(0, this.deployNum);
      }
    },
    checkArray(list) {
      const regex = /^(https?:\/\/)[^\s$.?#].[^\s]*$/i;
      for (const item of list) {
        if (item.radio === 2) {
          if (!regex.test(item.webUrl)) {
            return false;
          }
        }
      }
      return true;
    },
    async keywordAdAdd() {
      const list = this.selectList.map((item, index) => {
        item.blockConfId = this.blockConfId;
        item.site = index + 1;

        if (item.dragList && item.dragList.length > 0 && item.radio == 1) {
          item = { ...item, ...item.dragList[0] };
        }
        if (item.radio == 1) {
          item.webUrl = "";
        }
        if (item.radio == 2) {
          item.resType = 2001; //2001 外部链接
        }
        return item;
      });
      const data = {
        blockConfId: this.blockConfId,
        moduleType: 2,
        list,
      };
      // 图片合集校验
      if (this.styleCode == 201 || this.styleCode == 202) {
        const isValid = list.some((item, index) => {
          if (index == 0) {
            if (
              !item.imgUrl ||
              (item.radio == 2 && !item.webUrl) ||
              (item.radio == 1 &&
                (item.dragList.length <= 0 ||
                  (!item.dragList[0].apkId && !item.dragList[0].resType)))
            ) {
              return true;
            }
          }
        });
        if (isValid) {
          this.$message.error("请检查数据是否填写完整");
          return;
        }
        if (!this.checkArray(list)) {
          this.$message.error("请输入正确链接");
          return;
        }
        // // 网络链接校验
        // const isNetwork = list.some(function (item) {
        //   if (item.radio == 2 && IsURL(item.webUrl)) {
        //     return true;
        //   }
        // });
        // if (!isNetwork) {
        //   this.$message.error("请输入正确链接");
        //   return;
        // }
      }
      if (this.styleCode != 104) {
        data.list.forEach((element, index) => {
          if (element.isChecked) {
            data.list[index].isChecked = 1;
          } else {
            data.list[index].isChecked = 2;
          }
        });
      }
      if (this.styleCode == 104) {
        let arr = JSON.parse(JSON.stringify(data.list));
        arr.forEach((element, index) => {
          if (element.isChecked) {
            arr[index].isChecked = 1;
          } else {
            arr[index].isChecked = 2;
          }
        });
        data.list = arr;
      }
      const res = await savePageConfig(data);
      if (res) {
        this.$message.success("部署成功");
        this.$emit("closeBtn");
      }
    },
  },
};
</script>

<style lang="scss">
.contentWrapper {
  height: 400px;

  .leftCard,
  .rightCard {
    height: 100%;
    border: 1px solid #dcdfe6;
    border-radius: 6px;
  }

  .rightCard {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .summitContent {
      flex: 1;
      margin: 10px 0;
      overflow: auto;

      .selectList {
        height: 100%;
      }
    }

    .optionArea {
      text-align: end;
    }

    .itemArea {
      width: 95%;
    }
  }
}
</style>
